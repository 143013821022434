import React from 'react'
import TrueSizeLogo from './res/true-size-of-mapulator.png'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import {
   makeStyles
} from '@material-ui/core/styles'

export default function Welcome(props: {
   onPressEnter: () => void
}) {

   const styles = useStyles()

   return (
      <Grid container md={8} sm={8} xl={10}>
         <div className={styles.container}>
            <img alt='true-size-of-mapulator' style={{
               width: '50%',
               marginBottom: 10
            }} src={TrueSizeLogo} />
            <div style={{
               padding: '0 5% 0 5%'
            }}>
               <div style={{
                  margin: '20px 0 20px 0'
               }}>
                  <Typography variant='h5'>Welcome to the <b>TrueSizeOf</b> project from Mapulator</Typography>
                  <Typography style={{
                     marginTop: 5
                  }}>Measure Size and Distances of Planets, Stars and Black Holes</Typography>
               </div>
               <Typography variant='body1'>Our passion for map measurement and astronomy has just given birth to something we always wondered and we are now very excited to share this free tool with the world.</Typography>
            </div>
            <Paper style={{
               marginTop: 15,
               padding: 20,
               display: 'flex'
            }} elevation={1} variant='outlined'>
               <div style={{
                  marginRight: 20,
                  textAlign: 'left'
               }}>
                  <Typography variant='h6'>How it Works?</Typography>
                  <ul>
                     {[
                        'Click anywhere on the map to choose a place.',
                        'Search for stars, planets, blackholes',
                        'Add and Delete objects from the map',
                        'Click Simulate Distance to see the distance from the center of the sun'
                     ].map((text) => (<li style={{
                        margin: '2px 0 2px 0'
                     }}>{text}</li>))}
                  </ul>
               </div>
            </Paper>
            <Paper className={styles.inspirePanel} elevation={1} variant='outlined'>
               <div style={{
                  marginRight: 20,
                  textAlign: 'left'
               }}>
                  <Typography variant='h6'>Inspired By</Typography>
                  <Typography>This video from the Corridor Crew inspired us to build TrueSizeOf. This video shows how we are so small compared to some of the large celestial bodies that exist in the universe.</Typography>
               </div>
               <iframe
                  width="280"
                  height="157"
                  src="https://www.youtube.com/embed/GCTuirkcRwo?controls=0"
                  title="YouTube video player"
                  // frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
            </Paper>
            <Button disableElevation onClick={props.onPressEnter} style={{
               background: '#67a8e6',
               marginTop: 10,
               color: 'white'
            }} variant='contained'>Enter!</Button>
         </div>
      </Grid>
   )
}

const useStyles = makeStyles((theme) => {
   
   const breakpoints = theme.breakpoints
   return ({
      inspirePanel: {
         marginTop: 15,
         padding: 20,
         display: 'flex',
         [breakpoints.down('sm')]:{
            flexDirection: 'column',
            alignItems: 'center',
         }
      },
      container: {
         overflow: 'scroll',
         maxHeight: window.innerHeight - 100,
         flexDirection: 'column',
         textAlign: 'center',
         background: 'white',
         padding: 20
      }
   })
})