import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
   GoogleMap,
   useJsApiLoader,
   Circle,
   Marker,
   OverlayView,
   GroundOverlay,
} from '@react-google-maps/api';
import { Celestial, getCelestials } from './Celestials';
import ScaleSelector from './ScaleSelector';
import AddCelestial from './AddCelestial';
import TrueSizeOfLogo from './res/true-size-of-mapulator.png'
import Grid from '@material-ui/core/Grid'
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Facebook from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import CloseOutlined from '@material-ui/icons/CloseOutlined';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Twitter from '@material-ui/icons/Twitter';
import Web from '@material-ui/icons/Language';
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import BlankIcon from './res/blank.png'

export const API_KEY = 'AIzaSyCOs-3a1SjL1xiQGBku5fmjj44dIENJv70'

const geolib = require('geolib')

export default function Home(props:{
   onPressShowWelcome: () => void
}) {

   const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: API_KEY
   })

   const [scale, setScale] = useState(100)
   const [simulateDistance, setSimulateDistance] = useState(false)
   const [zoom, setZoom] = useState(0)
   const [isMapLoaded, setIsMapLoaded] = useState(false)
   const [celestialIds, setCelestialIds] = useState<string[]>([
      'sun',
      'mercury',
      'venus',
      'earth',
      'mars',
      'jupiter',
      'saturn',
      'uranus',
      'neptune'
   ])
   const [toolBoxVisible, setToolBoxVisible] = useState(true)
   const [showLoader, setShowLoader] = useState(false)
   const [hideLabels, setHideLabels] = useState(true)
   const [showImages, setShowImages] = useState(true)
   const [imageOpacity, setImageOpacity] = useState(0.9)

   const [location, setLocation] = useState<{
      lat: number,
      lng: number
   }>({
      lat: 40.76807950012483,
      lng: -73.98189879659887
   })

   const computed = useMemo<Celestial[]>(() => {
      return getCelestials(location, simulateDistance, scale, celestialIds)
   }, [simulateDistance, scale, location, celestialIds, showLoader])

   const [celestials, setCelestials] = useState<Celestial[]>(computed)

   const mapViewRef = useRef<google.maps.Map>()
   const onSelectScaleCallback = useCallback(onSelectScale, [scale])

   useEffect(() => {
      setCelestials(computed)
   }, [computed])
   

   useEffect(() => {
      if (navigator.geolocation != null) {
         navigator.geolocation.getCurrentPosition((position) => {
            setLocation({
               lat: position.coords.latitude,
               lng: position.coords.longitude
            })
         }, (error) => {
            console.log('Error Getting Current Location : ', error)
         }, {
            enableHighAccuracy: true,
            timeout: 10000
         })
      }
   }, [navigator.geolocation])

   useEffect(() => {
      if (celestials?.length > 0 && isMapLoaded == true) {
         const first = celestials[0]
         const last = celestials[celestials.length - 1]

         const leftEdge = geolib.computeDestinationPoint(first.center, first.radius, 270)
         var bounds = new google.maps.LatLngBounds({
            lat: leftEdge.latitude,
            lng: leftEdge.longitude
         });
         for (var i = 0; i < celestials.length; i++) {
            bounds.extend(celestials[i].center);
         }
         if(last){
            const rightEdge = geolib.computeDestinationPoint(last.center, last.radius, 90)
            bounds.extend(new google.maps.LatLng({
               lat: rightEdge.latitude,
               lng: rightEdge.longitude
            }))
         }
         mapViewRef.current?.fitBounds(bounds, {
            right: 100,
            left: 100,
            top: 100,
            bottom: 100
         });
      }
   }, [simulateDistance, scale, isMapLoaded, celestials, showLoader])

   if (isLoaded === false || showLoader == true) {
      return <div />
   }

   return (
      <Grid style={{
         width: '100%',
         height: '100%',
      }} container>
         <GoogleMap
            center={location}
            mapContainerStyle={{
               width: '100%',
               height: '100%'
            }}
            options={{
               zoomControl: false,
               scrollwheel: true,
               mapTypeControl: true,
               streetViewControl: false,
               fullscreenControl: false
            }}
            onZoomChanged={onZoomChanged}
            onClick={onTapOnMap}
            streetView={undefined}
            zoom={zoom}
            onLoad={onLoadMap}
            onUnmount={onUnmountMap}>
            {showImages == false && celestials.map((planet, index) => {
               return (
                  <Circle
                     key={planet.name}
                     draggable={simulateDistance == false}
                     center={planet.center}
                     onDragEnd={(e) => onDragEnd(e, index)}
                     options={{
                        fillColor: planet.color,
                        strokeWeight: 0,
                        fillOpacity: 0.85
                     }}
                     radius={planet.radius} />
               )
            })}
            {showImages == true && celestials.map((planet) => {

               const radius =  planet.radius * Math.sqrt(2)

               const ne = geolib.computeDestinationPoint(planet.center, radius, 45)
               const sw = geolib.computeDestinationPoint(planet.center, radius, 225)

               const SWLatLng = new google.maps.LatLng(sw.latitude, sw.longitude)
               const NELatLng = new google.maps.LatLng(ne.latitude, ne.longitude)
            
               return (
                  <OverlayView                     
                     mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                     bounds={new google.maps.LatLngBounds(
                        new google.maps.LatLng(SWLatLng.lat(), SWLatLng.lng()),
                        new google.maps.LatLng(NELatLng.lat(), NELatLng.lng()),
                     )}>
                        <img alt={planet.name} src={planet.image} style={{
                           width: '100%',
                           height: '100%',
                           opacity: imageOpacity,
                        }}/>
                     </OverlayView>
               )
            })}
            {hideLabels == false && celestials.map((planet) => {
               return (
                  <Marker
                     label={{
                        text: planet.name,
                        fontWeight: 'bold',
                     }}
                     icon={{
                        url: BlankIcon
                     }}
                     draggable={false}
                     position={planet.center}>
                  </Marker>
               )
            })}            
         </GoogleMap>
         <Grid>
            <div style={{
               position: 'absolute',
               top: 10,
               right: 10,
               background: 'white',
               padding: 10,
               textAlign: 'right',
               borderRadius: 5,
               borderWidth: 1,
               border: '1px solid #dddddd',
            }}>
               <div style={{
                  textAlign: 'center',
                  marginBottom: 10
               }}>
                  <a target={'_blank'} href='https://mapulator.app'><img style={{
                     width: '180px'
                  }} alt='true-size-of' src={TrueSizeOfLogo} /></a>
               </div>
               {toolBoxVisible == true && <div>
                  <div style={{
                     padding: 15,
                     borderRadius: 5,
                     border: '1px solid #dddddd',
                     marginBottom: 10
                  }}>
                     <div style={{
                        marginBottom: 5
                     }}>
                        <label>Simulate Distance</label>
                        <input
                           type="checkbox"
                           onChange={() => setSimulateDistance(!simulateDistance)}
                           checked={simulateDistance === true ? true : false} />
                     </div>
                     <div style={{
                        marginBottom: 5
                     }}>
                        <label>Hide Labels</label>
                        <input
                           type="checkbox"
                           onChange={() => setHideLabels(!hideLabels)}
                           checked={hideLabels === true ? true : false} />
                     </div>
                     <div style={{
                        marginBottom: 5,
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'right'
                     }}>
                        <div style={{
                           marginBottom: 5
                        }}>
                           <label>Show Images(Beta)</label>
                           <input
                              type="checkbox"
                              onChange={() => setShowImages(!showImages)}
                              checked={showImages === true ? true : false} />
                        </div>
                        {showImages == true &&  <input onChange={(e) => {
                           setImageOpacity(Number(e.target.value)/100)
                        }} type="range" min={20} max={100} defaultValue={imageOpacity * 100}></input>}
                     </div>
                  </div>
                  <ScaleSelector
                     value={scale}
                     onSelectScale={onSelectScaleCallback}
                     title='Select Radius of the Sun' />
                  <div style={{
                     marginTop: 10,
                     borderRadius: 5,
                     border: '1px solid #dddddd',
                     padding: 10,
                     textAlign: 'center'
                  }}>
                     <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 10,
                        alignItems: 'center'
                     }}>
                        <div style={{
                           textAlign: 'center',
                        }}>Celestials</div>
                        <button onClick={onClickClear}>Clear</button>
                     </div>
                     {celestials.map((object) => {
                        return (
                           <div style={{
                              display: 'flex',
                              alignItems: 'center'
                           }}>
                              <button onClick={() => onClickCelestial(object)} style={{
                                 width: '100%',
                                 margin: '2px 0 2px 0',
                                 marginRight: 5
                              }}>{object.name}</button>
                              <IconButton style={{
                                 height: 15
                              }} onClick={() => onRemoveCelestial(object)}><CloseOutlined/></IconButton>
                           </div>
                        )
                     })}
                     <AddCelestial onSelectCelestial={onSelectCelestial} />
                  </div>
                  <Paper variant='outlined' style={{
                     textAlign: 'center',
                     marginTop: 10
                  }}>
                     <a target={'_blank'} href='https://facebook.com/mapulator'><IconButton><Facebook /></IconButton></a>
                     <a target={'_blank'} href='https://instagram.com/mapulator'><IconButton><Instagram /></IconButton></a>
                     <a target={'_blank'} href='https://twitter.com/mapulator_app'><IconButton><Twitter /></IconButton></a>
                     <a target={'_blank'} href='https://www.linkedin.com/showcase/mapulator'><IconButton><LinkedIn /></IconButton></a>
                     <a target={'_blank'} href='https://mapulator.app'><IconButton><Web /></IconButton></a>
                  </Paper>
               </div>}
               <div style={{
                  textAlign: 'center',
                  marginTop: 5
               }}>
                  <IconButton onClick={() => {
                     setToolBoxVisible(!toolBoxVisible)
                  }}>{toolBoxVisible == true ? <ArrowUpward /> : <ArrowDownward />}</IconButton>
               </div>
            </div>
         </Grid>
      </Grid>
   )

   function onTapOnMap(e: google.maps.MapMouseEvent) {
      const latLng = {
         lat: e.latLng?.lat?.() ?? 0,
         lng: e.latLng?.lng?.() ?? 0
      }
      mapViewRef.current?.panTo(new google.maps.LatLng(latLng))
      setTimeout(() => {
         setLocation(latLng)
      }, 500);
   }

   function onDragEnd(e: google.maps.MapMouseEvent, index: number) {
      const __celestials = [...celestials]
      const celestial = celestials[index]
      const latLng = {
         lat: e.latLng?.lat?.() ?? 0,
         lng: e.latLng?.lng?.() ?? 0
      }
      celestial.center = {
         lat: latLng.lat,
         lng: latLng.lng
      }
      setCelestials(__celestials)
   }

   function onZoomChanged(){
      const __zoom = mapViewRef.current?.getZoom()
      if(__zoom){
         setZoom(__zoom)
      }
   }

   function onRemoveCelestial(celestial: Celestial) {
      const __celestialIds = [...celestialIds]
      const findIndex = __celestialIds.findIndex(c => celestial.id === c)
      if (findIndex >= 0) {
         __celestialIds.splice(findIndex, 1)
      }
      setCelestialIds(__celestialIds)
   }

   function onClickCelestial(celestial: Celestial) {
      
      const latLng = new google.maps.LatLng(celestial.center)
      const boundsList = geolib.getBoundsOfDistance({
         lat: latLng.lat(),
         lng: latLng.lng(),
      },celestial.radius)
      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < boundsList.length; i++) {
         bounds.extend(new google.maps.LatLng({
            lat: boundsList[i].latitude,
            lng: boundsList[i].longitude,
         }))
      }
      mapViewRef.current?.fitBounds(bounds,{
         bottom: 10,
         left: 10,
         right: 10,
         top: 10
      })
   }

   function onClickClear(){
      setCelestialIds([])
   }

   function onSelectScale(value: number) {
      setScale(value)
   }

   function onLoadMap(map: google.maps.Map) {
      mapViewRef.current = map
      setIsMapLoaded(true)
   }

   function onSelectCelestial(celestial: Celestial) {
      setCelestialIds([...celestialIds, celestial.id])
   }

   function onUnmountMap() {

   }

}