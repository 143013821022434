import React, { ChangeEvent, memo, useMemo, useState } from 'react'
import { Celestial, getAllCelestials } from './Celestials'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const celestials = getAllCelestials()

function AddCelestial(props: {
   onSelectCelestial: (celestial: Celestial) => void
}) {

   return (
      <div style={{
         width: '100%',
         textAlign: 'center',
         padding: "15px 0 5px 0",
      }}>
         <Autocomplete
            disablePortal
            getOptionLabel={(option) => option.name}
            options={celestials}
            onChange={(_, value) => {
               if (value) {
                  props.onSelectCelestial(value)
               }
            }}
            renderInput={(params) => (
               <TextField
                  {...params}
                  placeholder='Search Stars, Blackholes'
                  variant='outlined'
                  label="Add More Stars" />
            )}
         />
      </div>
   )
}

export default memo(AddCelestial)
