import Celestials from './Celestials.json'
import Sun from './res/sun.png'
import Mercury from './res/mercury.png'
import Venus from './res/venus.png'
import Earth from './res/earth.png'
import Mars from './res/mars.png'
import Jupiter from './res/jupiter.png'
import Saturn from './res/saturn.png'
import Uranus from './res/uranus.png'
import Neptune from './res/neptune.png'
import Blackhole from './res/blackhole.png'
import SunRedGiant from './res/sun-red-giant.png'

const geolib = require('geolib')

export interface Celestial {
   id: string,
   name: string,
   radius: number,
   distance: number
   color: string,
   center: any,
   type: string,
   image: any
}

export function getAllCelestials(): Celestial[] {

   const objects = Celestials as unknown as Celestial[]
   return objects
}

export function getCelestials(
   location: any,
   simulateDistance: boolean,
   scale: number,
   ids: string[]
): Celestial[] {

   const objects = Celestials as unknown as Celestial[]
   let filtered = objects.filter(
      (f:any) => ids.includes(f.id)
   )
   if(simulateDistance == true){
      filtered = filtered.sort((a,b) => a.distance - b.distance)
   }
   return addLocationProps(filtered, location, simulateDistance, scale)
}

export function searchCelestial(searchText: string) {
   const objects = Celestials as unknown as Celestial[]
   const result = objects.filter((o) => o.name.toLowerCase().includes(searchText.toLowerCase()))
   return result
}

export function addLocationProps(
   objects: Celestial[],
   location: any,
   simulateDistance: boolean,
   scale: number,
) {
   let celestials = [] as any[]
   const sunRadius = Celestials[0].radius
   const factor = scale / sunRadius
   let distance = -1 * sunRadius

   celestials = objects.map((object, index) => {
      let center = location
      distance = distance + object.radius
      const latLng = geolib.computeDestinationPoint(
         {
            latitude: location.lat,
            longitude: location.lng
         },
         (
            simulateDistance == true ?
               object.distance :
               distance
         ) * factor,
         90
      )
      distance = distance + object.radius
      center = {
         lat: latLng.latitude,
         lng: latLng.longitude
      }
      return {
         ...object,
         radius: object.radius * factor,
         center,
         image: imageForId(object.id, object.type)
      }
   })
   return celestials
}

function imageForId(id: string, type: string){
   switch (id) {
      case 'sun':
         return Sun
      case 'mercury':
         return Mercury
      case 'venus':
         return Venus
      case 'earth':
         return Earth
      case 'mars':
         return Mars
      case 'jupiter':
         return Jupiter
      case 'saturn':
         return Saturn
      case 'uranus':
         return Uranus
      case 'neptune':
         return Neptune
      case 'sun-red-giant':
         return SunRedGiant
      default:{
         if(type === 'blackhole'){
            return Blackhole
         }
         return Sun
      }
   }
}
