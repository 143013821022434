import React, { memo } from 'react'

export const ScaleOptions : {[key: number]: string} = {
   0.00001: '0.001 Centimeter',
   0.0001: '0.01 Centimeter',
   0.001: '0.1 Centimeter',
   0.01: '1 Centimeter',
   0.1: '10 Centimeter',
   0.0254: '1 Inch',
   0.254: '10 Inches',
   1: '1 Meter',
   10: '10 Meter',
   100: '100 Meter',
   500: '500 Meter',
   1000: '1 Kms',
   10000: '10 Kms',
   50000: '50 Kms',
   100000: '100 Kms',
   500000: '500 Kms',
   1000000: '1000 Kms',
   0.0686: 'Tennis Ball',
   0.11938: 'Basket Ball'
}

const sorted = Object.keys(ScaleOptions) as unknown as number[]
// const sorted =  keys.sort((a, b) => {
//    return a - b
// })

function ScaleSelector(props: {
   value: number,
   onSelectScale: (value: number) => void,
   title: string
}) {
   const { title, onSelectScale, value } = props
   return (
      <div style={{
         padding: 15,
         borderRadius: 5,
         border:'1px solid #dddddd',
         textAlign: 'center'
      }}>
         <p>{title}</p>
         <select style={{
            width: '100%'
         }} value={value} onChange={(event) => {
            onSelectScale(Number(event.target.value))
         }}>
            {sorted.map((key) => {
               return (
                  <option value={key}>{(ScaleOptions as any)[key]}</option>
               )
            })}
         </select>
      </div>
   )
}

export default memo(ScaleSelector)
