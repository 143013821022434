import React, { useEffect, useState } from 'react';
import './App.css';
import Home from './Home'
import Grid from '@material-ui/core/Grid'
import Modal from '@material-ui/core/Modal';
import Welcome from './Welcome'
import Typography from '@material-ui/core/Typography';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCDbaWrAry0mlFstCBoePnDKBlqcRgDcRI",
  authDomain: "logisian-mapulator-92210.firebaseapp.com",
  databaseURL: "https://logisian-mapulator-92210.firebaseio.com",
  projectId: "logisian-mapulator-92210",
  storageBucket: "logisian-mapulator-92210.appspot.com",
  messagingSenderId: "923634062989",
  appId: "1:923634062989:web:e7a0839cc4af71a40974c4",
  measurementId: "G-BQH2YESZ84"
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

function App() {

  const [showWelcome, setShowWelcome] = useState(true)

  useEffect(() => {
    const value = localStorage.getItem('showWelcome')
    if (value === 'false') {
      setShowWelcome(false)
    } else {
      setShowWelcome(true)
    }
  }, [])

  return (
    <Grid container style={{
      display: 'flex',
      flex: 1,
      width: window.innerWidth,
      height: window.innerHeight,
      background: 'black',
      alignItems: 'center'
    }}>
      <Modal
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          padding: 20,
        }}
        open={showWelcome}
        onClose={onCloseWelcome}>
        <Welcome onPressEnter={onCloseWelcome} />
      </Modal>
      <Home onPressShowWelcome={onPressShowWelcome} />
      <div style={{
        position: 'absolute',
        bottom: 0,
        textAlign: 'center',
        background: '#11111180',
        left: 80
      }}>
        <Typography style={{
          fontSize: 11,
          color: 'white',
          padding: "1px 10px 1px 10px"
        }}><a target={'_blank'} href="https://www.logisian.in">https://logisian.in</a></Typography>
      </div>
    </Grid>
  );

  function onCloseWelcome() {
    localStorage.setItem('showWelcome', 'false')
    setShowWelcome(false)
  }

  function onPressShowWelcome() {
    localStorage.setItem('showWelcome', 'true')
    setShowWelcome(false)
  }
}

export default App;
